$sm: 960px;

.mv-body {
  display: grid;
  height: 100vh;

  grid-column-gap: 0px;
  grid-row-gap: 0px;

  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr;


  @media (min-width: $sm) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 80px 30px 1fr;
  }
}

.mv-body-header {
  
  grid-area: 1 / 1 / 2 / 3;
  
}

.mv-body-nav {
  grid-area: 2 / 1 / 3 / 3;
 
}
.mv-body-content {
  grid-area: 2 / 1 / 3 / 2;
  @media (min-width: $sm) {
    grid-area: 3 / 1 / 4 / 3;
  }
}

.content-container {
  height: calc(100vh - 60px);
  display: grid;
  grid-template-rows: 60px 1fr;

  @media (min-width: $sm) {
    height: calc(100vh - 110px);
  }
}

.content-body {
  height: calc(100vh - 30px - 60px);
  position: relative;
  padding: 20px;

  @media (min-width: $sm) {
    height: calc(100vh - 80px - 30px - 60px);
  }
}


.content-body-split {
  @media (min-width: $sm) {
    height: calc(100vh - 80px - 30px - 60px);
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-template-areas: "content-nav content-main";
  }
}


.content-body-split-no-header {  
  @media (min-width: $sm) {
    padding-top: 10px;
    height: calc(100vh - 80px - 30px - 20px);
    .content-nav {   
        height: calc(100vh - 80px - 30px - 20px);     
    }
  }
}

.content-nav {
  width: 100%;
  @media (min-width: $sm) {
    width: 300px;
    height: calc(100vh - 80px - 30px - 60px);
    border-right: 1px solid lightgrey;
  }
}

.content-header {
  border-bottom: 1px solid lightgrey;
  position: relative;
}

.content-main-scrollable {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 20px;
}

.content-main-scrollable-fixed-width {
  max-width: 80em;
}

.content-main {
  height: calc(100% - 42px);
  width: calc(100% - 42px);
  padding: 20px;
}

.content-tabs {
  height: calc(100% - 28px);
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 50px) 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.content-tabs-fullheight {
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 50px) 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
.tabs-wrapper {
  height: calc(100% - 12px);
}
.tabs-tabs {
  grid-area: 1 / 1 / 2 / 2;
  width: calc(100vw - 320px);
}
.tabs-toolbar {
  grid-area: 2 / 1 / 3 / 2;
}
.tabs-content {
  height: calc(100% - 58px);
  grid-area: 3 / 1 / 4 / 2;
  position: relative;
}


.content-body-split .content-main-scrollable {
  border-left: 1px solid lightgrey;
}

.content-chart .dx-tabs {
  border: 0;
  .dx-tab {
    border-top: 1px solid #ccc;
  }
}