$sm: 960px;

a {
    text-decoration: none;
}
.error-boundary {
    background: #c51244;
    border: 1px solid #800;
    border-radius: 2px;
}

.dx-treelist-rowsview .dx-treelist-empty-space {
    @media (max-width: $sm) {
        width: 25px;
        height: 20px;
        font: 18px/18px DXIcons;
    }
}
.dx-treelist-rowsview .dx-treelist-expanded span,
.dx-treelist-rowsview .dx-treelist-collapsed span {
    @media (max-width: $sm) {
        font: 20px/20px DXIcons;
    }
}

h3.page-header {
    margin: 0;
    color: #800000;
    min-height: 40px;
}

.home-unauthenticated {
    margin-top: 150px;
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
}

.note {
    color: gray;
    font-size: 80%;
}

.dx-datagrid .dx-row > td {
    padding: 2px 5px;
}
.dx-datagrid .dx-header-row > td {
    padding: 7px 5px;
    font-weight: 600;
}
.dx-datagrid .dx-row-alt > td,
.dx-datagrid .dx-row-alt > tr > td {
    background-color: #eeeeee;
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
}
.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
    border-bottom: 0;
}

.compact-form {
    margin: 10px 20px;

    .dx-fieldset-header {
        margin: 0;
        font-weight: 500;
        font-size: 10px;
    }
    .dx-field {
        margin: 2px 0;
        padding: 0;
    }
    .dx-field-value {
        margin: 2px 0;
        padding: 0;
    }
    .dx-field-label,
    .dx-field-value-static {
        padding: 9px 5px 0 0;
    }
}

.dx-device-mobile .dx-fieldset {
    margin: 0;
    padding: 0;
}

.popup-padded {
    padding: 10px;
}

.modal-form {
    height: 100%;
    overflow: auto;

    .dx-field {
        margin: 5px 0;
        padding: 0;
    }
    .dx-field-value,
    .dx-field-value-static {
        padding: 0;
        width: 70%;
    }
    .dx-field-label {
        padding: 0 10px 0 0;
        color: #800;
        text-align: right;
        width: 30%;
    }
}

.fieldset-wrapped {
    border: 1px solid #ccc;
    border-radius: 2px;
    padding: 10px;
    margin: 20px 10px 10px 10px;
    position: relative;

    .dx-fieldset-header {
        color: #999;
        position: absolute;
        top: -8px;
        left: 10px;
        background: white;
        padding: 0 3px;
    }
}

.field-value-inline {
    display: flex;
    gap: 10px;
}

.dx-tabs-wrapper {
    display: block;
}
.dx-tabs {
    border: 1px solid #dee1e3;
    border-bottom: 0;
}
.tabs-toolbar {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    margin-top: -1px;
}

.dx-tab {
    border-bottom: 1px solid #ccc;
}
.dx-tab-selected {
    border-bottom: 1px solid #fff;
}
.dx-tab.dx-state-focused::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
    border-right: 1px solid maroon;
    border-left: 1px solid maroon;
    border-top: 1px solid maroon;
    border-bottom: 0;
    z-index: 1;
}

.dx-tab .dx-icon-close {
    margin: 0 0 0 0;
    background-position: 0 0;
    background-size: 18px 18px;
    padding: 0;
    font-size: 12px;
    text-align: center;
    line-height: 0;
}

input[type="color"] {
    padding: 0;
    width: 150%;
    height: 150%;
    margin: -25%;
}

.cp_wrapper {
    overflow: hidden;
    width: 20px;
    height: 20px;
    float: left;
}

.map-wrapper {
    position: relative;
    height: "500px";
    width: "calc(100% - 200px)";
}
.map-wrapper-small {
    position: relative;
    height: "80%";
    width: "100%";
}
.map-container {
    height: calc(100vh - 150px);
    min-height: 300px;

    @media (min-width: $sm) {
        height: calc(100vh - 350px);
    }
}

.map-sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 10000;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 2px 35px 0;
    border-radius: 4px;
}
.map-meter-popup {
    border-radius: 3px;
    padding: 10px;
}

.grid-buttons {
    display: flex;
    justify-content: space-between;
}
.grid-nowrap {
    white-space: nowrap;
}

.mv-breadcrumb {
    padding: 5px;
}

.dx-popup-wrapper > .dx-popup-fullscreen-width {
    border: 0;
}

.dx-field {
    margin: 0;
}
.dx-field-value {
    padding: 8px 15px 9px 0;
}

.dx-field-label {
    width: 100%;
    display: block;
    text-align: left;
    color: #800000;
    font-weight: 500;

    @media (min-width: $sm) {
        width: 200px;
        text-align: right;
        padding-right: 30px;
    }
}

.dx-field-value-static {
    @media (min-width: $sm) {
        width: calc(100% - 230px);
    }
}

.dx-field-value:not(.dx-widget) > .dx-button,
.dx-field-value:not(.dx-widget) > .dx-checkbox,
.dx-field-value:not(.dx-widget) > .dx-switch {
    float: left;
}

.dx-field-value,
.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button):not(.dx-datebox) {
    width: 100%;
    float: left;
    min-width: auto;

    @media (min-width: $sm) {
        width: calc(100% - 200px);
    }
}

.dx-field-value.dx-checkbox,
.dx-field-value:not(.dx-widget) > .dx-checkbox {
    margin: 0;
}

.dx-field-value-text {
    padding: 8px 15px 9px 0;
}

.mv-item-selection {
    border-top: 1px solid #ccc;
    margin-top: 20px;
}

.mv-item-selection a {
    display: block;
    border-bottom: 1px solid #ccc;
    padding: 5px 0 5px 20px;
    font-size: 160%;
    text-decoration: none;
}

.file-content {
    height: "100%";
    overflow-y: "auto";
    margin: 10px;
}

.button-with-hint {
    display: flex;
    gap: 10px;
    align-self: stretch;
    margin-bottom: 10px;
}

.dx-datagrid-header-panel .dx-toolbar {
    padding: 3px;
}

.file-wrapper {
    height: 200px;
    overflow-y: auto;
}

.dx-fileuploader-container {
    max-height: 300px;
    overflow-y: scroll;
}

.statusdot {
    display: inline-block;
    width: 8px;
    height: 8px;
    vertical-align: middle;
    border-radius: 50%;
    border: 1px solid rgba(53, 53, 53, 0.5);
    margin-right: 0.3em;
}

/* Loading page */
.loading-overlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.loader,
.loader:before,
.loader:after {
    background: #800000;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}
.loader {
    color: #800000;
    text-indent: -9999em;
    margin: 88px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}
.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: "";
}
.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}
.loader:after {
    left: 1.5em;
}
@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}
@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

/* Loading page end */

.button-invalid {
    color: red;
}

.debs-download-basic {
    width: 16px;
    height: 16px;
    text-indent: -99999px;
    overflow: hidden;
    display: inline-block;
    background-image: url("icons/excel.png");
    background-repeat: no-repeat;
}
.debs-download-complete {
    width: 16px;
    height: 16px;
    text-indent: -99999px;
    overflow: hidden;
    display: inline-block;
    background-image: url("icons/excelplus.png");
    background-repeat: no-repeat;
}
.debs-files {
    white-space: nowrap;
}
.file-uploader-nopadding * {
    padding: 0;
}

.help-icon {
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-right: 10px;
    text-indent: -99999px;
    overflow: hidden;
    display: inline-block;
    background-image: url("icons/help.png");
    background-repeat: no-repeat;
    vertical-align: bottom;
}

.rs-picker-menu {
    z-index: 1001 !important;
}

.alarm-history-item {
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 5px;
}
.alarm-history-item-author {
    display: block;
    border-top: 1px solid #eee;
    margin-top: 8px;
    font-size: 85%;
}
.alarm-history-item h5 {
    padding: 0 0 0 20px;
    min-height: 16px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin: 0;
    font-size: 85%;
    font-weight: bold;
}
.alarm-status-Ignore {
    background-image: url("/images/icons/16/gauge.png");
}
.alarm-status-Active {
    background-image: url("/images/icons/16/gauge2.png");
}
.alarm-status-Investigating {
    background-image: url("/images/icons/16/gauge1.png");
}
.alarm-status-Actioned {
    background-image: url("/images/icons/16/gauge0.png");
}

.license-active {
    color: #800000;
}



.content-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.content-nav li a {
    padding: 0.25em 0.75em;
    display: block;
    width: 100%;
}