$sm: 960px;


.content-error {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #800000;
    font-weight: bold;
  }

  
  
  // .content-container .dx-multiview-item-container {
  //   padding-top: 10px;
  // }
  
  .content-header h2 {
    font-size: 30px;
    font-weight: 800;
    color: #800;
    margin: 10px;
  }
  
  .header-buttons {
    @media (min-width: $sm) {
      position: absolute;
      right: 5px;
      top: 10px;
      text-align: right;
      line-height: 40px;
    }  
  }

  .header-buttons .dx-button {
    margin-right: 5px;
  }
  
  .mv-toolbar {
    flex-direction: column;
    margin-bottom: 10px;
    padding-top: 10px;

    @media (min-width: $sm) {
      display: flex;
      height: 40x;
      flex-direction: row;
      justify-content: flex-start;
      
    }
  }

  .mv-toolbar-spreadout {
    @media (min-width: $sm) {
      justify-content: space-between;      
    }
  }

  .mv-toolbar-small {
    padding: 5px;
    display: grid; 
    grid-auto-flow: row dense; 
    grid-auto-columns: 1fr; 
    grid-auto-rows: 1fr 1fr; 
    grid-template-columns: auto auto; 
    grid-template-rows: auto auto; 
    gap: 5px 0px; 
    grid-template-areas: 
      "buttons settings"
      "picker picker"; 
    justify-content: space-evenly; 
    justify-items: stretch; 
    align-items: center; 
    }
    .mv-toolbar-large {
      padding: 5px;
      display: grid; 
      grid-auto-flow: row dense; 
      grid-auto-columns: 1fr 1fr 1fr; 
      grid-auto-rows: 1fr; 
      grid-template-columns: max-content max-content max-content; 
      grid-template-rows: auto; 
      gap: 5px 0px; 
      grid-template-areas: 
        "buttons picker settings"; 
      justify-content: space-between; 
      justify-items: center; 
      align-items: center; 
      }


    .chart-toolbar-picker { grid-area: picker; }
    .chart-toolbar-buttons { grid-area: buttons; }
    .chart-toolbar-settings { grid-area: settings; }
  

  .toolbar-item {
    margin: 10px 0;
    width: 100%;
    display: flex;
    @media (min-width: $sm) {
      margin: 0 5px;
      width: auto;
    }
  }
  
  .dx-data-row .dx-button-has-icon {
      border: 0;
  }
  .dx-popup-content {
      padding: 0;
  }

.dx-device-mobile .dx-toolbar {
  padding-right: 5px;
}

.popup-content {
  padding: 20px;
}

.dx-treelist-header-panel {
  padding: 5px 5px 0 0;
}

.rs-picker-menu {
  z-index: 2000;
}



.alarms-toolbar-small {
  display: grid; 
  grid-auto-flow: row dense; 
  grid-auto-columns: 1fr 1fr 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: auto auto; 
  grid-template-rows: auto auto auto auto; 
  gap: 10px 5px; 
  grid-template-areas: 
    "alarms-toolbar-meters alarms-toolbar-filter"
    "alarms-toolbar-picker alarms-toolbar-picker"
    "alarms-toolbar-sort ."
    "alarms-toolbar-button alarms-toolbar-button"; 
  justify-content: start; 
  justify-items: stretch; 
  align-items: stretch; 
}
.alarms-toolbar-large {
  display: grid; 
  grid-auto-flow: row dense; 
  grid-auto-columns: 1fr 1fr 1fr; 
  grid-auto-rows: 1fr; 
  grid-template-columns: auto auto auto auto; 
  grid-template-rows: auto; 
  gap: 0px 10px; 
  grid-template-areas: 
    "alarms-toolbar-meters alarms-toolbar-picker alarms-toolbar-filter alarms-toolbar-button"; 
  justify-content: start; 
  justify-items: stretch; 
  align-items: stretch; 
}
.alarms-toolbar-picker { grid-area: alarms-toolbar-picker; }
.alarms-toolbar-sort { grid-area: alarms-toolbar-sort; }
.alarms-toolbar-filter { grid-area: alarms-toolbar-filter; }
.alarms-toolbar-meters { grid-area: alarms-toolbar-meters; }
.alarms-toolbar-button { grid-area: alarms-toolbar-button; }