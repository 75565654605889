.user-info {
  display: block;
  min-height: 20px;
  text-align: right;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .user-name {
    font-size: 14px;
    color: #000;
  }
}

.user-panel {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 0;
}

.user-panel-section-user {
  grid-area: 1 / 1 / 2 / 2;
  padding: 3px;
}
.user-panel-section-logout {
  grid-area: 1 / 2 / 2 / 3;
  padding: 3px;
}
.user-panel-section-org {
  grid-area: 2 / 1 / 3 / 3;
}

.user-panel-logout {
  cursor: hand;
  border: 0;
  color: #800000;
  background: none;
}

// Login button
.dx-button-mode-text.dx-state-focused,
.dx-button-mode-text.dx-state-hover,
.dx-button-mode-text.dx-state-active {
  background-color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dx-button .dx-button-content .dx-inkripple,
.user-button .dx-inkripple {
  display: none;
}

.button-login {
  width: 160px;
  height: 40px;
  border: none;
  outline: none;
  color: rgb(139, 1, 1);
  background: #fff;
  border: 2px solid rgb(139, 1, 1);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}
.button-login:hover {
  color: #fff;
  background: rgb(139, 1, 1);
  border: 2px solid rgb(139, 1, 1);
}
