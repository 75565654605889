.tn {
  padding-left: 20px;
  min-height: 16px;
  background-repeat: no-repeat;
  background-position: 0 50%;
}
.ts {
  background-image: url("/images/icons/16/folder.png");
}
.tu,
.tuf {
  background-image: url("/images/icons/16/user.png");
}
.tsg,
.tsgf {
  background-image: url("/images/icons/16/user_lock.png");
}
.to,
.tof {
  background-image: url("/images/icons/16/office_building.png");
}

.tonew {
  background-image: url("/images/icons/16/office_building_new.png");
}

.tg {
  background-image: url("/images/icons/16/metergroup.png");
}
.tm {
  background-image: url("/images/icons/16/gauge.png");
}
.tm0 {
  background-image: url("/images/icons/16/gauge0.png");
}
.tm1 {
  background-image: url("/images/icons/16/gauge1.png");
}
.tm2 {
  background-image: url("/images/icons/16/gauge2.png");
}
.tm3 {
  background-image: url("/images/icons/16/gauge3.png");
}
.tdisabled {
  color: #ccc;
}

.meter-tree-tree {
  
}
.meter-tree-include-children {
  text-align: right;
}