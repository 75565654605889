.link {
    cursor: pointer;
}

.homeMenu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    align-items: flex-start;
    margin-top: 100px;
}
.homeMenu a {
    display: block;
    width: 120px;
    padding: 63px 5px 8px 5px;
    background-position: 50% 5px;
    background-repeat: no-repeat;
    text-align: center;
    color: #000;
    text-decoration: none;
    border: 1px solid transparent;
    font-size: 100%;
    margin: 0;
}
.homeMenu a:hover {
    background-color: #ffc7c7;
    border: 1px solid #800000;
    border-radius: 3px;
}

.homeMenu a.dashboard {
    background-image: url('./../../css/icons/home/dashboard.png');
}
.homeMenu a.chart {
    background-image: url('./../../css/icons/home/chart.png');
}
.homeMenu a.account {
    background-image: url('./../../css/icons/home/user.png');
}
.homeMenu a.help {
    background-image: url('./../../css/icons/home/help.png');
}
.homeMenu a.meters {
    background-image: url('./../../css/icons/home/meter.png');
}
.homeMenu a.security {
    background-image: url('./../../css/icons/home/security.png');
}
.homeMenu a.alarms {
    background-image: url('./../../css/icons/home/alarms.png');
}
.homeMenu a.subscription {
    background-image: url('./../../css/icons/home/subscription.png');
}
.homeMenu a.data {
    background-image: url('./../../css/icons/home/data.png');
}
.homeMenu a.debs {
    background-image: url('./../../css/icons/home/debs.png');
}
