$sm: 960px;

.dx-popup-content .mv-nav-menu-body {
  display: grid;
  height: 100%;

  grid-column-gap: 0px;
  grid-row-gap: 0px;

  grid-template-columns: 1fr;
  grid-template-rows: 60px 1fr 80px;


}

.mv-header-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: auto;

  @media (min-width: $sm) {
    flex-direction: row;
    align-content: start;
  }
}

.mv-userpanel {
  flex: 0 0 auto;
}

.mv-menu-button {
  margin-right: 5px;
  align-self: center;
}

.mv-logo {
  flex: 0 0 auto;
  width: 170px;
  overflow: hidden;

  @media (min-width: $sm) {
    width: 270px;
  }
}

.header-logo {
  background-image: url("../../images/metervue_150.png");
  background-repeat: no-repeat;
  display: block;
  width: 170px;
  height: 50px;
  background-position: 50% 50%;
  text-indent: -10000px;

  @media (min-width: $sm) {
    width: 270px;
    height: 80px;
    background-image: url("../../images/metervue_250.png");
  }
}

.mv-navigation {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  @media (min-width: $sm) {
    margin: 0;
    height: 32px;
    line-height: 30px;
    text-align: center;
    color: white;
    background-color: #800000;
  }
}

.mv-navigation a {
  display: block;
  border-bottom: 1px solid #ccc;  
  padding: 5px 0 5px 20px;
  font-size: 160%;
  text-decoration: none;

  @media (min-width: $sm) {
    height: 30px;
    border-bottom: 2px solid transparent;
    line-height: 30px;
    display: inline-block;
    border-bottom: 0;
    padding: 0;
    font-size: 100%;
    color: white;
    background-color: #800000;
    margin: 0 8px;
  }
}

.mv-navigation a.mv-nav-active,
.mv-navigation a:hover  {
  background: #ccc;
  
  @media (min-width: $sm) {
    border-bottom: 2px solid #ffffff;
    color: #ffffff;
    background: #800000;
  }
}

.mv-nav-menu-user {
  position: sticky;
  bottom: 0;
  padding: 20px;

  .user-panel-section {
    display: block;
  }
}